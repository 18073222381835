.footer {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media (max-width: 920px) {
    background: rgba(63, 46, 90, 0.16);
    padding: 0 12px 32px 12px;
    margin: 0 -12px;
  }

  hr {
    margin-bottom: 40px;
  }
}

.menu_list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 4px;
  padding: 0;
}

.menu_link {
  padding: 12px 0;
  color: var(--color-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.72;
  cursor: pointer;
  transition: all 0.3s;

  // .footer-menu__link:hover
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  // .footer-menu__link .material-icons
  .icon {
    color: var(--color-white, #fff);
  }
}

.get_app_btns {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.social_block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 250px;
  margin: 0 auto;
}

.social_link {
  position: relative;
  width: 48px;
  height: 48px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--Corner-BTN, 16px);
  border: 1px solid rgba(255, 255, 255, 0.12);
  overflow: hidden;

  img {
    object-fit: contain;
    opacity: 0.72;
    z-index: 1;
    transition: all 0.3s;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s;
  }

  &.telegram {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #138ac5;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #138ac5;
    }
  }

  &.discord {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #5661f5;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #5661f5;
    }
  }

  &.twitter {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #1d9bf0;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #1d9bf0;
    }
  }

  &.tiktok {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #6767f6;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #6767f6;
    }
  }

  &.youtube {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #f63e3e;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #f63e3e;
    }
  }

  &.twitter_x {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #000;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #000;
    }
  }

  &.reddit {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #ff4500;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #ff4500;
    }
  }

  &.facebook {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #3b5998;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #3b5998;
    }
  }

  &.patreon {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #f96753;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #f96753;
    }
  }

  &.snap {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #fefc3e;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #fefc3e;
    }
  }

  &.onlyfans {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        #00aeef;
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        #00aeef;
    }
  }

  &.instagram {
    &::before {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(
          180deg,
          #ff45f2 0%,
          #ff55b8 31.1%,
          #ff5863 68.2%,
          #ffcb77 100%
        );
    }

    &::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3) 0%,
          rgba(0, 0, 0, 0.3) 100%
        ),
        linear-gradient(
          180deg,
          #ff45f2 0%,
          #ff55b8 31.1%,
          #ff5863 68.2%,
          #ffcb77 100%
        );
    }
  }

  @media (hover: hover) {
    &:hover {
      &::before,
      & img {
        opacity: 1;
      }
    }
  }

  &:active {
    &::after,
    & img {
      transition: 0.1s;
      opacity: 1;
    }
  }
}

.lang {
  width: 76px;
  margin: 0 auto;
  position: relative;
}

.lang.active .lang_dropdown {
  display: block;
}

.lang_select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(156, 136, 188, 0.2);
  padding: 8px 0px 8px 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white, #fff);
  text-transform: uppercase;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: rgba(156, 136, 188, 0.32);
    }
  }

  span {
    min-width: 28px;
  }

  .icon {
    color: rgba(255, 255, 255, 0.5);
  }
}

.lang_dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  transform: translateY(-16px);
  z-index: 2;
  border-radius: 8px;
  background: rgba(153, 153, 153, 0.08);
  backdrop-filter: blur(8px);
  display: none;

  // overflow-y: scroll;
  // height: 300px;

  &::-webkit-scrollbar {
    width: 3px;

    &-track {
      background: #3f3a52;
    }

    &-thumb {
      border: 3px solid #261f39;
    }
  }

  &.to_down {
    bottom: unset;
    top: 100%;
    transform: translateY(16px);
  }
}

.lang_dropdown_item {
  height: 40px;
  padding: 4px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 8px;
  color: #ccc;
  transition: all 0.3s;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: var(--color-white, #fff);
    }
  }
}

.support_link {
  padding: 13px 16px;
  text-decoration: none;
  color: rgba(222, 167, 255, 0.72);
  text-align: center;
  font-size: 18px;
  // font-family: var(--font-family-segoe-ui) sans-serif;
  font-weight: 500;
  display: block;
  transition: all 0.3s;

  @media (hover: hover) {
    &:hover {
      color: rgba(222, 167, 255, 1);
    }
  }
}

.dislaimer {
  text-align: center;
  color: var(--color-white, #fff);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.32;
}

.legal_info {
  @extend .dislaimer;
  font-size: 10px;
}
